import { Routes } from '@angular/router';
import { AuthGuard } from '../../shared/authguards/auth.guard';

export const PARTNERSHIP_OFFERS_ROUTES: Routes = [
  {
    path: "partnership-offers",
    loadComponent: () => import('./partnership-offers.component').then(m => m.PartnershipOffersComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: "lyfery",
        loadComponent: () => import('./lyfery/lyfery.component').then(m => m.LyferyComponent),
        data: { title: 'pageTitles.partnershipOffers' }
      }
    ],
  },
];
